/* global techfeed:true */
$(function() {
  'use strict';

  // login
  var $loginDialog = $('#login-dialog');
  // signup
  var $signupDialog = $('#signup-dialog');
  var $signupForms = $('.signup-form');
  var $emailInputs = $('.signup-email');

  var $signupMessage = $signupForms.find('.signup-message');

  // signup form dialog
  var $signupButton = $('.show-signup-dialog');
  if ($signupButton.length > 1 && $signupDialog.length === 1) {
    $signupButton.click(function(e) {
      e.preventDefault();
      $loginDialog.modal('hide');
      $signupDialog.modal();
    });
  }

  /**
   * メールアドレスの入力状態と他の入力要素の状態を同期する
   */
  function syncEmail2Button() {
    var $emailInput = $(this);
    var $form = $emailInput.parents('form.signup-form');
    var $signupButton = $form.find('.signup-button');
    $form.find('.signup-message').hide();

    var email = $emailInput.val();
    var emailValid = techfeed.validators.validateEmail(email);
    $signupButton.attr('disabled', !emailValid);
  }
  $emailInputs.on('focus input keyup change blur', syncEmail2Button);

  /**
   * サインアップ処理
   */
  $signupForms.on('submit', function(e) {
    e.preventDefault();

    var $form = $(this);
    var $emailInput = $form.find('input.signup-email');
    var $signupButton = $form.find('.signup-button');
    $signupMessage.hide();

    var email = $emailInput.val();
    if (!techfeed.validators.validateEmail(email)) {
      dangerMsg('メールアドレスの形式が不正です。');
      return;
    }
    $signupButton.attr('disabled', true);
    $emailInput.attr('disabled', true);
    var timezoneOffset = new Date().getTimezoneOffset();
    var data = {
      email: email,
      timezone: {offset: timezoneOffset}
    };
    $.ajax({
      method: 'post',
      url: '/api/people/signup',
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json'
    })
    .then(function() {
      $emailInput.hide();
      $signupButton.hide();
      infoMsg('ご登録確認のメールをお送りしました。ご確認ください。');

      // ログインダイアログが表示されている可能性があるので、非表示にする
      setTimeout(function() {
        $signupDialog.modal('hide');
      }, 4000);
    })
    .fail(function(xhr) {
      let message = '';
      switch (xhr.status) {
        case 406: // Not Acceptable: 指定されたメールアドレスのユーザが既に存在し、メール検証済みの場合。
          message = getErrorMessage(xhr);
          infoMsgHtml(message);
          break;
        case 400: // Bad Request: emailかtimezoneが指定されていない場合
        case 422: // Unprocesssable Entity: emailやtimezoneが指定されているが誤っている場合
          message = '入力内容が正しくありません。確認後、再度お試しください。';
          dangerMsg(message);
          break;
        default:
          message = 'エラーが発生しました。時間をおいて、再度お試しください。';
          dangerMsg(message);
      }
      $emailInput.attr('disabled', false);
    });
  });

  function getErrorMessage(xhr) {
    const errMsg = ((xhr.responseJSON && xhr.responseJSON.error) || {}).message;
    let message;
    if (errMsg === 'already exists email') {
      message = 'メールアドレスは既に登録済みですが、本人確認がとれていません。<br>' +
        '<button type="button" data-loading-text="Loading..." class="btn btn-primary" id="sendConfirmEmail">' +
        '登録確認メールを送る</button>';
    } else if (errMsg === 'already registered') {
      message = 'メールアドレスは既に登録済みです。<a href="#" class="show-login-dialog">ログインしますか？</a>';
    } else {
      message = 'エラーが発生しました。時間をおいて、再度お試しください。';
    }
    return message;
  }

  $signupDialog.on('show.bs.modal', function() {
    var $form = $(this).find('form');
    $form.find('.signup-message').hide();
    $form.find('.signup-button')
      .attr('disabled', true)
      .show();
    $form.find('.signup-email')
      .attr('disabled', false)
      .val('')
      .show();
  });

  /**
   * 確認メール送信
   */
  $signupMessage.on('click', '#sendConfirmEmail', (ev) => {
    ev.preventDefault();
    $(ev.target).button('loading');

    var $emailInput = $signupForms.find('input.signup-email');
    // 確認メール送信
    $.ajax({
      method: 'post',
      url: '/api/people/sendConfirmationMail',
      data: {email: $emailInput.val()},
      dataType: 'json'
    })
      .then(() => {
        const message = '登録確認メールをお送りしました。メールをご確認下さい。';
        infoMsg(message);
      })
      .fail(function() {
        // console.log('error', xhr.responseJSON);
        const message = 'エラーが発生しました。時間をおいて、再度お試しください。';
        dangerMsg(message);
      });
  });

  function infoMsg(message) {
    return $signupMessage
      .removeClass('alert-danger')
      .addClass('alert-info')
      .text(message).show();
  }

  function infoMsgHtml(message) {
    return $signupMessage
      .removeClass('alert-danger')
      .addClass('alert-info')
      .html(message).show();
  }

  function dangerMsg(message) {
    return $signupMessage
      .removeClass('alert-info')
      .addClass('alert-danger')
      .text(message).show();
  }

});
